import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { CategoryTopBannerBuilderComponent } from '@tvlgiao/bc-supermarket-builder-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "category-page"
    }}>{`Category Page`}</h1>
    <h2 {...{
      "id": "content-top-banner"
    }}>{`Content Top Banner`}</h2>
    <p><img alt="content top banner on category page" src={require("../img/home1-category-page-top-banner.png")} /></p>
    <p>{`You can enable this banner on all category pages in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > `}<strong parentName="p">{`Top banner on category page`}</strong>{` set to `}<inlineCode parentName="p">{`Show`}</inlineCode>{`.`}</p>
    <p>{`After enabled, you can edit the banner content by go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click button `}<strong parentName="p">{`Create a Banner`}</strong>{`. click button `}<strong parentName="p">{`Create a Banner`}</strong>{`. In `}<strong parentName="p">{`Banner Content`}</strong>{`, click `}<inlineCode parentName="p">{`HTML`}</inlineCode>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <CategoryTopBannerBuilderComponent mdxType="CategoryTopBannerBuilderComponent" />
    <p>{`Choose other options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Visible`}</strong>{`: Yes`}</li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "bulk-order-feature"
    }}>{`Bulk Order feature`}</h2>
    <p><img alt="Bulk Order" src={require("../img/bulk-order.png")} /></p>
    <p>{`This feature allows to display products in any category as table layout. Customer can quickly choose and add the selected products to cart at the same time. It's ideal for wholesale and large stores. `}</p>
    <p>{`Enable it in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Products`}</strong>{` > `}<strong parentName="p">{`Category pages`}</strong>{` > tick on `}<strong parentName="p">{`Show bulk order mode`}</strong>{`.`}</p>
    <p>{`To display Bulk Order layout for a specific category, edit the category and choose `}<strong parentName="p">{`Template Layout File`}</strong>{` = `}<inlineCode parentName="p">{`bulk-order`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "search-in-category-feature"
    }}>{`Search in Category feature`}</h2>
    <p><img alt="quick search in category" src={require("../img/theme-editor-search-in-category.png")} /></p>
    <p>{`This feature allows to quickly search products on the current category. `}</p>
    <p>{`Enable this feature in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Products`}</strong>{` > `}<strong parentName="p">{`Category page`}</strong>{` > `}<strong parentName="p">{`Show quick search in category`}</strong>{` = `}<inlineCode parentName="p">{`Show`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "sub-category-thumbnails"
    }}>{`Sub-Category Thumbnails`}</h2>
    <p><img alt="show-subcategories-grid" src={require("../img/show-subcategories-grid.png")} /></p>
    <p>{`Theme supports displaying sub-category thumbnails. Enable this feature in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Products`}</strong>{` > `}<strong parentName="p">{`Category pages`}</strong>{`, tick `}<strong parentName="p">{`Show subcategories grid`}</strong>{`. The thumbnails are the sub-category images.`}</p>
    <p>{`If you only use the category images for these thumbnails, and hide it on the category pages, see the instruction `}<a parentName="p" {...{
        "href": "/faqs#hide-the-category-images-on-category-pages"
      }}>{`Hide the category images on category pages`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      